import { useEffect, useRef, useState } from 'react'
import Loader from 'react-loaders'
import emailjs from '@emailjs/browser'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import CoffeeLogo from '../../assets/images/coffee-logo-compressed.png'

const formDefault = {
  from_name: '',
  from_email: '',
  subject: '',
  message: '',
}

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const refForm = useRef()
  const mapCoordinates = [41.8781, -87.6298] // [lat, long]
  const [form, setForm] = useState(formDefault)

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        refForm.current,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          alert('Message Sent!')
          resetForm()
        },
        (error) => {
          alert('Message sending failed...')
          console.log(error)
        }
      )
  }

  const handleOnChange = (e) => {
    setForm((prevData) => ({ ...prevData, [e.target.name]: e.target.value }))
  }

  const resetForm = () => {
    setForm({
      from_name: '',
      from_email: '',
      subject: '',
      message: '',
    })
  }

  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              strArray={'Contact me'.split('')}
              idx={15}
              letterClass={letterClass}
            />
          </h1>

          <p>
            Feel free to contact me for any inquiries. May it be business or
            something we can talk about. :D
          </p>

          <div className="contact-form">
            <form ref={refForm} onSubmit={sendEmail}>
              <input
                name="to_name"
                value={process.env.REACT_APP_EMAILJS_TO_NAME}
                style={{ display: 'none' }}
              />
              <ul>
                <li className="half">
                  <input
                    type="text"
                    name="from_name"
                    placeholder="Name"
                    value={form.from_name}
                    onChange={(e) => handleOnChange(e)}
                    required
                  />
                </li>

                <li className="half">
                  <input
                    type="email"
                    name="from_email"
                    placeholder="Email"
                    value={form.from_email}
                    onChange={(e) => handleOnChange(e)}
                    required
                  />
                </li>

                <li>
                  <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    value={form.subject}
                    onChange={(e) => handleOnChange(e)}
                  />
                </li>

                <li>
                  <textarea
                    name="message"
                    placeholder="Message"
                    value={form.message}
                    onChange={(e) => handleOnChange(e)}
                    required
                  ></textarea>
                </li>

                <li>
                  <input className="flat-button" type="submit" value="SEND" />
                </li>
              </ul>
            </form>
          </div>
        </div>

        <div className="info-map">
          Tabudlong, Kynt
          <br />
          United States of America
          <br />
          Chicago, Illinois, 60613
          <br />
          <span>kynt.tabudlong@gmail.com</span>
        </div>

        <div className="map-wrap">
          <MapContainer center={mapCoordinates} zoom={13}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={mapCoordinates}>
              <Popup className="popup-cont">
                <img src={CoffeeLogo} alt="coffee" />
                <span>Coffee?</span>
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Contact
