import { useEffect, useState } from 'react'
import Loader from 'react-loaders'

import AnimatedLetters from '../AnimatedLetters'
import Logo from './Logo'
import './index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              strArray={'About me'.split('')}
              idx={13}
              letterClass={letterClass}
            />
          </h1>

          <p>
            Hi, I'm Kynt Tabudlong! Software engineer with 5 years working
            experience on web development as a full stack developer.
          </p>

          <p>
            Originally from the medical field, but later realized the tech
            industry is my calling. I want to build stuff, experiment on the
            what ifs, and conceptualize solutions for daily convenience. Always
            curious and ever want to expand my knowledge, may it be technical,
            or mechanical.
          </p>

          <p>
            Started my web development journey on 2018. Was tasked to maintain a
            homegrown product. Fixing bugs, new features, customizations,
            upgrade scripts for mysql migrations. This was during my first 3
            years as a web developer, learning the SOLID principles slowly
            integrating them into coding habits.
          </p>

          <p>
            Later on, decided to learn ReactJS, VueJS, Typescript, and Laravel.
            Building applications using these tech, learning design patterns,
            migrating applications to a better architecture, all attributed to
            my skillset.
          </p>

          <p>
            A gamer by heart. I love playing games. These type of games stimulate my problem solving skills,
            ang generally molded my competitive nature. Always striving for the
            best, either go BIG or go home, no in betweens.
          </p>
        </div>

        <Logo />
      </div>

      <Loader type="pacman" />
    </>
  )
}

export default About
