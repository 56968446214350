import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faVuejs,
  faGit,
  faHtml5,
  faJs,
  faLaravel,
  faReact,
} from '@fortawesome/free-brands-svg-icons'

import './index.scss'

import AnimatedLetters from '../AnimatedLetters'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const nameArr = 'Kynt'.split('')
  const jobArr = 'Web Developer.'.split('')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  }, [])

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _11`}>i,</span>
            <br />
            <span className={`${letterClass} _12`}>I</span>
            <span className={`${letterClass} _13`}>'</span>
            <span className={`${letterClass} _14`}>m</span>
            <span style={{ margin: '0 5px' }}>&nbsp;</span>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArr}
              idx={15}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArr}
              idx={22}
            />
          </h1>

          <h2>Full Stack Developer | Laravel | ReactJS | VueJS | MYSQL</h2>
          <Link to="/contact" className="flat-button">
            CONTACT ME
          </Link>
        </div>

        {/* <Logo /> */}
        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faVuejs} color="#2884D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faLaravel} color="#DD0031" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJs} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGit} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>

      <Loader type="pacman" />
    </>
  )
}

export default Home
